import React from "react";

export default function AboutCera() {
  return (
    <>
      {/* <div style={{ height: "100px" }}></div> */}
      <div className="container fs-5">
        <p>
          Jai Jawan Pipes and Sanitaryware deals in various cera products
          (commode, faucets, tiles, shower, bathtub)
        </p>
        <h1>CERA: Elevating Everyday Living with Innovative Products</h1>
        <p>
          In the dynamic world of modern living, where innovation and design
          converge, CERA stands out as a beacon of excellence. With a rich
          legacy spanning decades, CERA has consistently delivered top-notch
          products that redefine the way we experience our homes. From bathroom
          solutions to tiles and kitchen appliances, CERA is synonymous with
          quality, innovation, and style. In this blog post, we'll explore some
          of the standout products offered by CERA that have been transforming
          living spaces worldwide.
        </p>
        <h3>Sanitaryware Marvels:</h3>
        <p>
          CERA has been a trailblazer in the realm of sanitaryware, bringing
          forth an impressive range of products that seamlessly blend
          functionality with aesthetics. The company's collection of
          sanitaryware includes exquisite designs of water closets, wash basins,
          urinals, and bidets. CERA's commitment to water conservation is
          evident in its eco-friendly designs that not only enhance the bathroom
          experience but also contribute to a sustainable future.
        </p>
        <h3>Faucets that Reflect Elegance:</h3>
        <p>
          The faucets from CERA are more than just water dispensers; they are
          pieces of art that adorn your bathrooms and kitchens. Whether you
          prefer a classic look or a more contemporary style, CERA's faucet
          range caters to diverse tastes. These faucets are not only visually
          appealing but also built with precision engineering, ensuring
          durability and efficiency.
        </p>
        <h3>Tiles Redefined</h3>
        <p>
          CERA's tiles are a testament to the brand's dedication to innovation
          and design. From floor tiles that offer a perfect blend of form and
          function to wall tiles that transform spaces into visual masterpieces,
          CERA's tile collection is vast and versatile. Whether you're revamping
          your bathroom, kitchen, or living room, CERA tiles provide the perfect
          canvas for your design aspirations.
        </p>
        <h3>Kitchen Appliances for the Modern Chef:</h3>
        <p>
          CERA's foray into kitchen appliances has been nothing short of
          revolutionary. The company offers a range of cutting-edge kitchen
          products, from sleek chimneys that enhance air quality to advanced
          hobs and cooktops that make cooking a joyous experience. CERA's
          kitchen appliances combine technology with elegance, catering to the
          needs of the modern chef.
        </p>
        <h3>Wellness with Steam and Shower Solutions:</h3>
        <p>
          In a world that increasingly values holistic well-being, CERA's steam
          and shower solutions offer a luxurious escape within the confines of
          your home. These products provide a spa-like experience, promoting
          relaxation and rejuvenation. CERA's commitment to wellness is evident
          in the thoughtful design and advanced features of their steam and
          shower solutions.
        </p>
      </div>
    </>
  );
}
