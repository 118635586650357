import Carousel from "react-bootstrap/Carousel";
import image1 from "../Assets/carousel-assets/1.png";
import image2 from "../Assets/carousel-assets/2.png";
import myVideo from "../Assets/carousel-assets/faucets-video.mp4";
import firstCuroselImages from "../Assets/carousel-assets/first-curosel-image.png";


function CarouselHomePage() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          src={firstCuroselImages}
          className="d-block w-100"
          alt="Woman Reading a Book"
        />
        <Carousel.Caption>
          <h3>Jai Jawan Hardware and Sanitaryware</h3>
          <p>The Complete Bathroom Solution</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={image1}
          className="d-block w-100"
          alt="Woman Reading a Book"
        />
        <Carousel.Caption>
        <h3>Jai Jawan Hardware and Sanitaryware</h3>
          <p>The Complete Bathroom Solution</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          src={image2}
          className="d-block w-100"
          alt="Woman Reading a Book"
        />
        <Carousel.Caption>
        <h3>Jai Jawan Hardware and Sanitaryware</h3>
          <p>The Complete Bathroom Solution</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        {/* <img src="https://mdbcdn.b-cdn.net/img/Photos/Slides/img%20(40).webp" className="d-block w-100" alt="Woman Reading a Book"/> */}
        <video className="d-block w-100"  autoPlay muted>
          <source src={myVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Carousel.Caption>
        <h3>Jai Jawan Hardware and Sanitaryware</h3>
          <p>The Complete Bathroom Solution</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselHomePage;
