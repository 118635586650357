import React, { useState } from "react";

const ContactForm = () => {

  const initialFormData = {
    name: "",
    email: "",
    message: "",
    phoneN: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   message: "",
  //   phoneN: '',
  //   // Add other form fields as needed
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await sendFormDataToPHP(formData);
    } catch (error) {
      //   console.error('Error sending data:', error);
    }
    resetForm();
  };

  const sendFormDataToPHP = async (data) => {
    try {
      const response = await fetch("https://jjmpl.com/php/sendData.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      //   console.log('Data sent successfully:', responseData);
    } catch (error) {
      //   console.error('Error sending data:', error);
      throw error;
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
  };
  return (
    <>
      <div className="container my-5">
        <h1 className="text-center">Contact Us</h1>
        <form id="contact_form" name="contact_form" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name_input">Name*</label>
            <input
              type="text"
              required
              maxLength="50"
              className="form-control"
              id="name_input"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
          </div>
          <div className="mb-3 row">
            <div className="col">
              <label htmlFor="email_addr">Email:*</label>
              <input
                type="email"
                required
                maxLength="50"
                className="form-control"
                id="email_addr"
                name="email"
                placeholder="name@example.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="col">
              <label htmlFor="phone_input">Phone</label>
              <input
                type="tel"
                maxLength="50"
                className="form-control"
                id="phone_input"
                name="phoneN"
                placeholder="Phone"
                value={formData.phoneN}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="message">Message*</label>
            <textarea
              className="form-control"
              id="message"
              name="message"
              rows="3"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="my-4">
            <div
              className="g-recaptcha"
              data-sitekey="6LchOyEUAAAAAPo9l0WmIgFkGu5D6Gyq-MjsIHS1"
            ></div>
          </div>
          <button type="submit" className="btn btn-primary px-4">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
