import React, { useRef, useState } from "react";
import "./MarqueeWrapper.css"; // Import your CSS file

const MarqueeCarousel = () => {
  const marqueeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const dragStart = (e) => {
    if (!isDragging) return;
    marqueeRef.current.scrollLeft -= e.movementX;
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="marquee-wrapper"
      style={{ userSelect: "none" }}
      ref={marqueeRef}
    >
      <div
        className="marquee-content scrollingX"
        onMouseDown={() => setIsDragging(true)}
        onMouseMove={dragStart}
        onMouseUp={stopDragging}
      >
        {/* Repeat the card-testimonial content as needed */}
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/1.png"
              />
              <img src="https://jjmpl.com/img/1.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/2.png"
              />
              <img src="https://jjmpl.com/img/2.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/3.png"
              />
              <img src="https://jjmpl.com/img/3.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/4.png"
              />
              <img src="https://jjmpl.com/img/4.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/5.png"
              />
              <img src="https://jjmpl.com/img/5.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/6.png"
              />
              <img src="https://jjmpl.com/img/6.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        <div className="card-testimonial">
          <article>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet="https://jjmpl.com/img/1.png"
              />
              <img src="https://jjmpl.com/img/1.png" alt="" />
            </picture>
            <h4>Title 1</h4>
            <article className="short-description">
              <p>Description 1</p>
            </article>
          </article>
        </div>
        {/* Repeat the card-testimonial content as needed */}
      </div>
    </div>
  );
};

export default MarqueeCarousel;
