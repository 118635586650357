import React, { useState } from 'react';

const FormData = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: 'Hello123',
    // Add other form fields as needed
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Send data to PHP script
      await sendFormDataToPHP(formData);
    } catch (error) {
    //   console.error('Error sending data:', error);
    }
  };

  const sendFormDataToPHP = async (data) => {
    try {
      const response = await fetch('https://jjmpl.com/php/sendData.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
    //   console.log('Data sent successfully:', responseData);
    } catch (error) {
    //   console.error('Error sending data:', error);
      throw error; 
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </label>
      {/* Add other form fields as needed */}
      <button type="submit">Submit</button>
    </form>
  );
};

export default FormData;
