import React from "react";
import sanitarywareImg from "../Assets/products/Sanitaryware.png";
import waterTankImg from "../Assets/products/Watertank.png";
import waterPumpImg from "../Assets/products/waterpumps.png";
import pipesandfittingImg from "../Assets/products/pipesandfitting.png";
import tilesImag from "../Assets/products/tiles.png";
import mirrorImg from "../Assets/products/mirror.png";

function Cards() {
  return (
    <>
      <div className="container card-deck row mx-auto my-4 customCardCSS">
        <h3 className="productH3">Products</h3>
        <div className="card col-sm m-1">
          <img
            src={sanitarywareImg}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Sanitaryware</h5>
            <p className="card-text">
              This is a longer card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
        <div className="card col-sm m-1">
          <img
            src={waterTankImg}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Water Tank</h5>
            <p className="card-text">
              This card has supporting text below as a natural lead-in to
              additional content.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
        <div className="card col-sm m-1">
          <img
            src={waterPumpImg}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Water Pumps and Submersible</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This card has even longer content
              than the first to show that equal height action.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
      <div className="container card-deck row mx-auto">
        <div className="card col-sm m-1">
          <img
            src={pipesandfittingImg}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Pipes and Fitting</h5>
            <p className="card-text">
              This is a longer card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
        <div className="card col-sm m-1">
          <img
            src={tilesImag}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Tiles</h5>
            <p className="card-text">
              This card has supporting text below as a natural lead-in to
              additional content.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
        <div className="card col-sm m-1">
          <img
            src={mirrorImg}
            className="card-img-top card-deck-img"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Mirror</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This card has even longer content
              than the first to show that equal height action.
            </p>
            <p className="card-text">
              <small className="text-muted">
                <a href="#" className="cardahref">
                  Know More
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
