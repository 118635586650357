import "./App.css";
import Navbarbar from "./Components/Navbarbar";
import Footer from "./Components/Footer";
import { Aboutus } from "./Pages/Aboutus";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import Homepage from "./Components/Homepage";
import AboutCera from "./Brands/AboutCera";
import React, { useState, useEffect } from "react";
import FormData from "./Brands/Formdata";
import DisplayData from "./Components/DisplayData";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import MarqueeCarousel from "./Components/MarqueeCarousel";

function App() {
  const [isAuthenticated, setAuthenticated] = useState("false");
  // sessionStorage.setItem("isAuthenticated", isAuthenticated);
  // const isAuthenticatedCheck = sessionStorage.getItem("isAuthenticated");
  console.log("from app.js " + isAuthenticated);

  const handleLogin = () => {
    if (isAuthenticated) {
      Navigate("/dashboard");
    } else {
      Navigate("/login");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Use smooth scrolling animation
    });
  };
  return (
    <>
      <Navbarbar />
      <Router>
        <Routes>
          <Route path="/about" Component={Aboutus} />
          <Route path="/" exact Component={Homepage} />
          <Route path="/display" Component={DisplayData} />
          <Route path="/marquee" Component={MarqueeCarousel} />
          <Route
            path="/cera"
            Component={AboutCera}
            handleWindow={scrollToTop}
          />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
