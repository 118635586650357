import React from "react";

function AboutSection() {
  return (
    <div className="container mt-5">
      <h2 className="text-center">About Jai Jawan Pipes and Sanitaryware</h2>
      <br />
      <p>
        Jai Jawan Pipes and Sanitaryware, where we provide top-quality products
        and excellent customer service to meet all your needs. Our company is
        dedicated to providing you with the best possible experience, whether
        you are a homeowner, contractor, or business owner.
      </p>
      <p>
        We specialize in providing high-quality sanitaryware products, including
        bathroom fixtures, cleaning supplies, and other products designed to
        help maintain a clean and healthy environment. Our sanitaryware products
        are available in a wide range of styles and designs, ensuring that you
        can find the perfect solution to meet your needs.
      </p>
    </div>
  );
}

export default  AboutSection;