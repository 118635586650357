import React from "react";

export default function HomepageMessage() {
  return (
    <>
      <div className="fs-4">
      <div style={{ marginTop: "10px", height: "80px" }}></div>
      <div className="container">
        <p>
          Welcome to <b>Jai Jawan Pipes and Sanitaryware</b>, where quality
          meets reasonable and affordable prices! Explore our extensive range of
          faucets, sanitaryware, and other products. At Jai Jawan, we are
          dedicated to providing you with the finest in sanitaryware,
          durability, and stylish faucets and showers. Whether you're building a
          new home or renovating your current one, we have all the products you
          need to make your home stylish. We offer the perfect solutions to meet
          your needs. Experience excellence in every detail as you navigate
          through our curated selection. Thank you for choosing Jai Jawan -
          where your home dreams become a reality!
        </p>
      </div>
      </div>
    </>
  );
}
