import Carousel from "react-bootstrap/Carousel";
import ceraImg from "../Assets/brands/cera.png";
import cromptonImg from "../Assets/brands/crompton.png";
import kerovitImg from "../Assets/brands/kerovit.png";
import lubiImg from "../Assets/brands/lubi.png";
import trufloImg from "../Assets/brands/truflo.png";
import sonaImg from "../Assets/brands/sona.png";
import { Link } from 'react-router-dom';


function Brands() {
  return (
    <div className="container my-5">
      <h3 className="text-center">Brands in We Deal</h3>
      <div className="row">
        <div className="col-lg-4 col-md-12 mb-4">
        <Link to="/cera">
          <img
            src={ceraImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="cera india"
          /></Link>
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <img
            src={trufloImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="Tru flo by hindware"
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <img
            src={cromptonImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="Los Angeles Skyscrapers"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-12 mb-4">
          <img
            src={lubiImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="Skyscrapers"
          />
        </div>

        <div className="col-lg-4 col-md-6 mb-4">
          <img
            src={kerovitImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="New York City"
          />
        </div>
        <div className="col-lg-4 col-md-6 mb-4">
          <img
            src={sonaImg}
            className="img-fluid rounded-4 shadow-2-strong"
            alt="American Home"
          />
        </div>
      </div>
    </div>
  );
}

export default Brands;
