import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import "./table.css";

const DisplayData = ({ isAuthenticated, onLogout }) => {

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch("https://jjmpl.com/php/reactDisplay.php")
      .then((response) => response.json())
      .then((data) => setTableData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h1>Table Data</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{row.phone_number}</td>
              <td>{row.message}</td>
              <td>{row.created_at}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <h2>Welcome to the Dashboard</h2>
        <button onClick={onLogout}>Logout</button>
      </div>
    </div>
  );
};

export default DisplayData;
