import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import jjPandeypur from "../Assets/jj-pandeypur.avif";
import jjNawalpur from "../Assets/jj-nawalpur.jpeg";


function HeroSection() {
  return (
    <>
      <div style={{ height: "10px" }}></div>
      <div className="container my-5">
        <h2 className="text-center">Jai Jawan Pipe and Sanitaryware</h2>
        <h5 className="text-center">Our Branches</h5>
        <div className="row">
          <div className="col-sm border">
            <div className="containerImg">
              <img src={jjNawalpur} className="img-fluid" />
              <div className="centeredImg">
                <h4>Jai Jawan Pipe and Sanitaryware</h4>
                <p>
                  Add: Sh.3/60-K-12-S, Nawalpur, Natiniyadai, Bada Lalpur,
                  Lamhi, Varanasi, Uttar Pradesh 221003
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm text-center border">
            <div className="containerImg">
              <img src={jjPandeypur} className="img-fluid"/>
              <div className="centeredImg">
                <h4>Jai Jawan Pipe and Hardware</h4>
                <p>
                  Add: S 8/310- 1, Hukul Ganj Road, Khajuri Colony, Varanasi,
                  Uttar Pradesh 221002
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
