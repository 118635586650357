import React from "react";
import keshariNandanImg from "../Assets/keshari-nandan-patel.png";
import JaiPraskahImg from "../Assets/jai-prakash-singh.png";

export function Aboutus() {
  return (
    <>
      {/* <div style={{ height: "100px" }}></div> */}
      <div className="container fs-5">
        <h2>About US</h2>
        <br />
        <p>
          Jai Jawan Pipes and sanitaryware, where we provide top-quality
          products and excellent customer service to meet all your needs. Our
          company is dedicated to providing you with the best possible
          experience, whether you are a homeowner, contractor, or business
          owner.
        </p>
        <br />
        <p>
          We specialize in providing high-quality sanitaryware products,
          including bathroom fixtures, cleaning supplies, and other products
          designed to help maintain a clean and healthy environment. Our
          sanitaryware products are available in a wide range of styles and
          designs, ensuring that you can find the perfect solution to meet your
          needs.
        </p>
        <br />
        <p>
          In addition to our sanitaryware products, we also offer a full range
          of hardware products to help you get the job done. From hand tools to
          power tools, our hardware products are designed to meet the needs of
          both DIY enthusiasts and professional contractors. We only offer
          products from reputable manufacturers, ensuring that you get the best
          possible quality and performance.
        </p>
        <br />
        <p>
          At our company, we believe that excellent customer service is the key
          to success. Our knowledgeable and friendly staff is always here to
          assist you with your questions, concerns, and needs. We understand
          that every customer is unique, and we strive to provide personalized
          service that meets your individual needs.
        </p>
        <br />
        <p>
          Our commitment to quality, reliability, and customer satisfaction is
          what sets us apart from the competition. We are constantly looking for
          ways to improve our products and services, ensuring that we remain at
          the forefront of the industry.{" "}
        </p>
        <br />
        <p>
          Thank you for choosing our Jai Jawan. We look forward to serving you
          and helping you find the perfect solution to meet your needs.
        </p>
      </div>

      <div className="container my-5">
        <h2>
          Know More - Jai Jawan Pipe and Hardware & Jai Jawan Pipe and
          Sanitaryware
        </h2>
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col-md-6">
            <img
              className="img-fluid"
              alt="Jai Prakash Singh - Jai Jawan Pipe and hardware"
              src={JaiPraskahImg}
            />
            <h3 className="mt-3">Jai Prakash Singh</h3>
            <h5>Jai Jawan Pipe and hardware</h5>
            <p>
              Add: S 8/310- 1, Hukul Ganj Road, Khajuri Colony, Varanasi, Uttar
              Pradesh 221002
            </p>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={keshariNandanImg}
              alt="Keshari Nandan Patel - Jai Jawan Pipe and Sanitaryware"
            />
            <h3 className="mt-3">Keshari Nandan Patel</h3>
            <h5>Jai Jawan Pipe and Sanitaryware</h5>
            <p>
              Add: Sh.3/60-K-12-S, Nawalpur, Natiniyadai, Bada Lalpur, Lamhi,
              Varanasi, Uttar Pradesh 221003
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
