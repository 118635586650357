import React from 'react'
import AboutSection from './AboutSection'
import Brands from './Brands'
import Cards from './Cards'
import HeroSection from './HeroSection'
import CarouselHomePage from './CarouselHomePage'
import ContactForm from './ContactForm'
import HomepageMessage from './HomepageMessage'
import MarqueeCarousel from './MarqueeCarousel'

export default function Homepage() {
  return (
    <>
      <CarouselHomePage/>
      <HomepageMessage/>
      <HeroSection/>
      <Cards/>
      <Brands/>
      <MarqueeCarousel/>
      <AboutSection/>
      <ContactForm/>
    </>
  )
}
